.simple-keyboard-tablet .hg-button {
  font-size: 60px !important;
  height: 60px !important;
  width: 60px !important;
}

.simple-keyboard-tablet .hg-button-confirm {
  font-size: 30px !important;
}

.simple-keyboard-tablet .hg-button-dotcom {
  font-size: 30px !important;
}

.simple-keyboard.hg-layout-numeric .hg-button {
  flex-grow: 1 !important;
  max-width: unset !important;
}
